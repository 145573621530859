<script setup>
import { WdsSetup } from '@wds/components';

const props = defineProps({ error: Object });
const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;

const message = computed(() => String(props.error?.message || ''));
const is404 = computed(() => props.error?.statusCode === 404 || message.value?.includes('404'));
const isDev = ['dev', 'test'].includes(envData?.RELEASE_ENV);

function handleError() {
  return clearError({ redirect: '/' });
}
</script>

<template>
  <WdsSetup />
  <div v-if="isDev">
    <pre class="error">{{ error }}</pre>
    <button
      class="error-btn"
      @click="handleError"
    >
      Go Back
    </button>
  </div>
  <NuxtLayout :name="is404 ? '404' : '500'">
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.error {
  background: $wds-color-orange-light;
  color: $wds-color-black;
  font-size: $wds-font-size-body-3;
  margin: $wds-spacing-m;
  padding: $wds-spacing-m;
  text-wrap: pretty;
}

.error-btn {
  background: $wds-color-orange-darker;
  color: $wds-color-white;
  border-radius: $wds-border-radius-s;
  padding: $wds-spacing-s;
  margin: 0 auto;
  display: flex;
}
</style>
