import { TRACKING_EMPTY, userLoggedStatus } from '~/tracking/variables';
import { useCurrencyStore } from '~/stores/currency';
import { useAccount } from '~/composables/useAccount.js';
import { ca } from '~/lib/channelAttribution.js';

export function getCommonTracking(nuxtApp) {
  const appConfig = useRuntimeConfig();
  const locale = nuxtApp?.$i18n?.locale;
  const currencyStore = useCurrencyStore();
  const userCurrency = currencyStore.getCurrentCurrency;
  const hasLoggedInUser = toValue(useAccount.hasLoggedInUser);

  const channelAttributionTrackingData = ca?.getLastChannelAttribution() || null;

  return {
    getGtmOptions: {
      gtmID: appConfig?.public?.GTM_ID,
      gtmSiteLang: locale?.value,
      gtmSearchCurrency: userCurrency?.code,
      gtmApplicationEnv: appConfig?.public?.RELEASE_ENV,
      gtmHwReleaseVersion: appConfig?.public?.RELEASE_VERSION,
      gtmDomainName: appConfig?.public?.DEFAULT_BASE_URL,
      gtmUserStatus: hasLoggedInUser ? userLoggedStatus.loggedIn : userLoggedStatus.loggedOut,
      gtmCustNo: 0,
      gtmUserID: 0,
    },
    getSegmentOptions: {
      site_language: locale?.value,
      release_version: appConfig?.public?.RELEASE_VERSION,
      application_environment: appConfig?.public?.RELEASE_ENV,
      site_currency: userCurrency?.code,
      domain_name: appConfig?.public?.DOMAIN_URL,
      login_status: hasLoggedInUser ? userLoggedStatus.loggedIn : userLoggedStatus.loggedOut,
      referrer: channelAttributionTrackingData?.referrer || TRACKING_EMPTY,
      entry_page: channelAttributionTrackingData?.entrypage || TRACKING_EMPTY,
      ref_source: channelAttributionTrackingData?.source || TRACKING_EMPTY,
      affiliate_id: channelAttributionTrackingData?.affiliate || TRACKING_EMPTY,
      gclid: channelAttributionTrackingData?.gclid || TRACKING_EMPTY,
      page_type: channelAttributionTrackingData?.entrypage || 'homepage',
      ga_clientid: TRACKING_EMPTY,
      redesign_version: TRACKING_EMPTY,
      country: TRACKING_EMPTY,
      city: TRACKING_EMPTY,
      continent: TRACKING_EMPTY,
      device_category: TRACKING_EMPTY,
    },
  };
}
