import { GA_ACCOUNTS } from '~/config/analytics';

export default defineNuxtPlugin(() => {
  const testingStore = useTestingStore();
  const isGoogleTagManagerEnabled = testingStore.hasEnabledFeature('web_googleTagManager');

  const { isTrackingAllowed } = checkGdpr();

  if (process.server) {
    return;
  }

  if (isGoogleTagManagerEnabled && isTrackingAllowed) {
    const analytics = (window.dataLayer = window.dataLayer || []);

    function gtag() {
      /* eslint-disable-next-line prefer-rest-params */
      analytics.push(arguments);
    }

    gtag('js', new Date());

    GA_ACCOUNTS.forEach(({
      ID,
      ATTR,
    }) => {
      gtag('config', ID, ATTR || {});
    });

    return { provide: { gtag } };
  }
});
