export default defineNuxtPlugin(() => {
  const replaceUrlCharacters = (url) => {
    return url ? url?.replace(/[\W_]+/g, '-')?.toLowerCase() : '';
  };

  const addSlashOnEnd = (value) => {
    return !value || value.endsWith('/') ? value : `${value}/`;
  };

  const buildQueryDecoded = (url, queryParams) => {
    const params = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${decodeURIComponent(value)}`)
      .join('&');
    return `${url}?${params}`;
  };

  const setHref = (str) => (window.location.href = str);

  return {
    provide: {
      url: {
        replaceUrlCharacters,
        addSlashOnEnd,
        buildQueryDecoded,
        setHref,
      },
    },
  };
});
