import { createInstance } from '@optimizely/optimizely-sdk';

export const useOptimizely = () => useState('optimizely', () => {});

export default defineNuxtPlugin(async (nuxtApp) => {
  const optimizely = useOptimizely();

  if (process.server) {
    const appConfig = useRuntimeConfig();

    /* CREATE OPTIMIZELY COOKIE */
    const optimizelyUserCookie = useCookie('optimizely-aid', {
      domain: appConfig.public.COOKIE_DOMAIN,
      maxAge: 63072000,
      path: '/',
      secure: appConfig.public.COOKIE_SECURE,
    });

    if (!optimizelyUserCookie.value) {
      // ASSIGN UNIQUE ID TO COOKIE VALUE
      optimizelyUserCookie.value = crypto.randomUUID();
    }

    // CREATE OPTIMIZELY INSTANCE
    const optimizelyKey = appConfig.public.OPTIMIZELY_SDK_KEY;
    const optimizelyInstance = createInstance({
      sdkKey: optimizelyKey || false,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: 60000, // 1 minute,
      },
      eventBatchSize: 10,
      eventFlushInterval: 1000,
    });

    await optimizelyInstance.onReady();

    const optimizelyUser = optimizelyInstance.createUserContext(optimizelyUserCookie.value, {});
    const userDecisions = optimizelyUser.decideAll();
    const currentEnabledFeatures = Object.keys(userDecisions).filter((ud) => userDecisions[ud].enabled);

    /* PARSE THE CUSTOM FEATURE TOGGLES FROM THE COOKIE (IF ANY) */
    if (optimizelyUserCookie.value.match(/\|+/)) {
      optimizelyUserCookie.value.split(',').forEach((feature) => {
        const [key, status] = feature.split('|');
        const featureIndex = currentEnabledFeatures.indexOf(key);

        if (status.toLowerCase() === 'on' && featureIndex < 0) {
          currentEnabledFeatures.push(key);
        }
        if (status.toLowerCase() === 'off' && featureIndex >= 0) {
          currentEnabledFeatures.splice(featureIndex, 1);
        }
      });
    }

    /* ASSIGN THE VARIABLES (IF ANY) TO EACH ENABLED FEATURE TOGGLE AND SHARE IT WITH CLIENT SIDE */
    optimizely.value = currentEnabledFeatures.reduce((prevValue, feature) => {
      prevValue[feature] = userDecisions[feature]?.variables;
      return prevValue;
    }, {});
  }

  /* POPULATE PINIA STORE WITH THE USER'S ENABLED FEATURES AND VARIABLES */
  const testingStore = useTestingStore(nuxtApp.$pinia);
  testingStore.setEnabledFeatures(optimizely.value);
});
