import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const appConfig = useRuntimeConfig();

  if (appConfig.public?.NODE_ENV !== 'development' && appConfig.public?.NODE_ENV !== 'dev') {
    Sentry.init({
      app: [vueApp],
      dsn: appConfig.public.SENTRY_DSN,
      environment: appConfig.public?.NODE_ENV || '',
      integrations: [Sentry.browserTracingIntegration()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: appConfig.public?.NODE_ENV === 'production' ? 0.5 : 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/www\.hostelworld\.com/],
      sampleRate: appConfig.public?.NODE_ENV === 'production' ? 0.5 : 1.0,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, {
            event,
            hint,
          });
        }
        // Continue sending to Sentry
        return event;
      },
    });

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
      }),
    );
    Sentry.attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  }
});
