export default defineNuxtPlugin(async () => {
  const checkMessage = (event, isOpened) => {
    let json;
    try {
      json = JSON.parse(event.data);
    } catch (event) {
      return;
    }

    if (json) {
      if (json.message === 'cm_loading') {
        isOpened = true;
      }
      if (json.message === 'remove_iframe' || (!isOpened && json.message === 'submit_preferences')) {
        window.location.reload();
      }
    }
  };

  const trustarcOrigin = 'https://consent-pref.trustarc.com';
  // @TODO: This might need a refactor
  const baseUrl = window?.location?.origin || 'https://www.hostelworld.com';
  const iframeWasOpen = false;
  window.addEventListener(
    'message',
    (event) => {
      if (![trustarcOrigin, baseUrl].includes(event.origin)) {
        return;
      }
      checkMessage(event, iframeWasOpen);
    },
    { passive: true },
  );
});
