import { checkIfFile } from '~/lib/urls.js';

export default defineNuxtRouteMiddleware((from) => {
  const runtimeConfig = useRuntimeConfig();
  const testingStore = useTestingStore();
  const envData = runtimeConfig?.public;
  let actualPath = from?.path;
  const actualQueryParams = new URLSearchParams(from?.query);
  let actualQueryParamsString = '';

  const isRedirectCountryEnabled = testingStore.hasEnabledFeature('web_redirectCountry');
  const isRedirectCityEnabled = testingStore.hasEnabledFeature('web_redirectCity');
  const isRedirectPropertyEnabled = testingStore.hasEnabledFeature('web_redirectProperty');
  const isRedirectDistrictEnabled = testingStore.hasEnabledFeature('web_redirectDistrict');
  const isRedirectAreaEnabled = testingStore.hasEnabledFeature('web_redirectArea');
  const isRedirectRegionEnabled = testingStore.hasEnabledFeature('web_redirectRegion');
  const isRedirectBlackFridayEnabled = testingStore.hasEnabledFeature('web_redirectBlackFriday');

  const routeParamsKeys = from?.params;

  let shouldRedirect = false;
  let isUserForbiddenToAccess = false;

  // don't touch error routes
  if (actualPath === '/__nuxt_error') {
    return;
  }

  // @TODO: This impacts korean characters, so we are removing for now
  // Ex: /ko/hoseuterdeur/p/3262/banana-벙garro-maui-hoseuter/
  // redirects from uppercase to lowercase ex: /Products -> /products
  /*
  if (actualPath?.match(/[A-Z]/)) {
    actualPath = actualPath?.toLowerCase();
    shouldRedirect = true;
  }
  */

  // don't redirect files and add trailing slash
  if (!checkIfFile(actualPath) && !actualPath?.endsWith('/')) {
    actualPath += '/';
    shouldRedirect = true;
  }

  // This is a temporary/hacky solution until we migrate every page, since middleware per page was breaking
  // Property page
  if (routeParamsKeys?.propertyType && routeParamsKeys?.propertyId && routeParamsKeys?.propertyName) {
    isUserForbiddenToAccess = !isRedirectPropertyEnabled;

    // District page
  } else if (
    routeParamsKeys?.propertyType
    && routeParamsKeys?.continent
    && routeParamsKeys?.country
    && routeParamsKeys?.city
    && routeParamsKeys?.district
  ) {
    isUserForbiddenToAccess = !isRedirectDistrictEnabled;

    // City page
  } else if (
    routeParamsKeys?.propertyType
    && routeParamsKeys?.continent
    && routeParamsKeys?.country
    && routeParamsKeys?.city
  ) {
    isUserForbiddenToAccess = !isRedirectCityEnabled;

    // Area page
  } else if (
    routeParamsKeys?.propertyType
    && routeParamsKeys?.continent
    && routeParamsKeys?.country
    && routeParamsKeys?.area
  ) {
    isUserForbiddenToAccess = !isRedirectAreaEnabled;

    // Region page
  } else if (
    routeParamsKeys?.propertyType
    && routeParamsKeys?.continent
    && routeParamsKeys?.country
    && routeParamsKeys?.region
  ) {
    isUserForbiddenToAccess = !isRedirectRegionEnabled;

    // Country page
  } else if (routeParamsKeys?.propertyType && routeParamsKeys?.continent && routeParamsKeys?.country) {
    isUserForbiddenToAccess = !isRedirectCountryEnabled;
    // Debug page
  } else if (from?.path?.includes('_debug')) {
    isUserForbiddenToAccess = true;

    // Black Friday Deals
  } else if (from?.path?.includes('/deals/black-friday/') && !isRedirectBlackFridayEnabled) {
    isUserForbiddenToAccess = true;
  } else {
    isUserForbiddenToAccess = false;
  }

  // Redirects users if they try to visit a page, that isn't yet migrated
  if (
    isUserForbiddenToAccess === true
    && (from?.query?.dev === '1' || ['dev', 'test'].includes(envData?.RELEASE_ENV))
  ) {
    console.warn('### Users won\'t be able to access this page in production');
    isUserForbiddenToAccess = false;
  }

  if (shouldRedirect && !isUserForbiddenToAccess && actualPath && actualPath !== '/') {
    actualQueryParamsString
      = actualQueryParams && actualQueryParams?.size > 0 ? `?${actualQueryParams.toString()}` : '';
    console.error(
      `### Redirected from ${from?.path}${actualQueryParamsString} to ${actualPath}${actualQueryParamsString}`,
    );
    return navigateTo(`${actualPath}${actualQueryParamsString}`, { redirectCode: 301 });
  }

  if (isUserForbiddenToAccess && actualPath !== '/') {
    return navigateTo('/');
  }
});
